<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :query-values="queryValues"
    :query-selects="querySelects"
    entity-type="parish"
    locale-section="pages.parishes"
    field-section="parishes"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "ParishDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        _name: parish
        parish
        hundred {
          id
        }
        municipality {
          id
        }
        toraId
      `,
      querySelects: /* GraphQL */ `
        hundred: hundreds {
          id
          hundred
          province {
            province
            country {
              country
            }
          }
        }
        municipality: municipalities {
          id
          municipality
          county {
            county
            country {
              country
            }
          }
        }
      `,
      inputAttributes: {
        toraId: { type: "number" },
        parish: { required: true },
        hundred: { required: true },
        municipality: { required: true }
      }
    };
  }
};
</script>
